<template>
  <dashboard>
    <el-row id="employer" :gutter="15">
      <el-col :xs="24">
        <div class="back">
          <!-- <router-link :to="'/employer/jobs'">
            <a href="#"
               class="el-button el-button--info">
              <i class="el-icon-back mr-2"/>Back
            </a>
          </router-link> -->
          <a href="#" class="el-button el-button--info" @click.prevent="$router.go(-1)">
            <i class="mr-2 el-icon-back" />Back
          </a>
        </div>
        <div v-if="job" class="jobview">
          <div id="applications">
            <h4 class="orange-text bold mb-3 mt-4">Applicants</h4>
              <div class="freelancer-profile">
              <hr>
              <el-card v-if="job.applications && job.applications.length == 0">
                <p class="text-center mt-3">
                  There are no applicants for this job yet.
                </p>
              </el-card>
              <el-card v-for="application in job.applications" :key="application.application_id">
                <el-row :gutter="15" type="flex">
                  <el-col :span="3">
                    <router-link :to="`/employer/application/${application.application_id}`">
                      <img :src="profilePhotoUrl(application.member)" class="rounded-circle w-100">
                    </router-link>
                  </el-col>
                  <el-col :span="18" class="align-self-center">
                    <router-link :to="`/employer/application/${application.application_id}`">
                      <p class="bold small mb-1" :class="{ blur: application.member.blurred }">{{ application.member.first_name }} {{ application.member.last_name }}</p>
                      <!-- <el-rate v-model="value" disabled /> -->
                    </router-link>
                    <p class="small mb-2 mt-1">
                      <span class="bold gray-text">Intro:</span>
                      <span v-text="application.member.profile.intro" />
                    </p>
                    <p class="small mb-2">
                      <span class="bold gray-text">Skills:</span>
                    </p>
                    <skills-list :list="application.member.freelancer_skills" />
                  </el-col>
                  <el-col :span="3" class="text-center text-lg-right">
                    <router-link :to="`/employer/application/${application.application_id}`">
                      <el-button type="warning" size="small" class="" plain circle icon="el-icon-more" />
                    </router-link>
                  </el-col>
                </el-row>
              </el-card>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </dashboard>
</template>

<script>
import moment from 'moment'
import Dashboard from '~/components/Dashboard/Dashboard'
import Job from '~/models/Jobs/EmployerJob'
import showsProfilePhoto from '~/mixins/showsProfilePhoto'
import showsJobDetails from '~/mixins/showsJobDetails'
import SkillsList from '~/components/Skills/List'

export default {
  components: { Dashboard, SkillsList },

  mixins: [showsProfilePhoto, showsJobDetails],

  filters: {
    usDateFormat (value) {
      return moment(value).format('LL');
    },
  },

  data () {
    return {
      value: 5,
      tempRouteId: this.$route.params.job_id - 10000,
      Job: new Job(this.$route.params.job_id - 10000),
    }
  },

  computed: {
    job () {
      return this.Job.data
    },
    deactivated () {
      return this.job.job_status === 'closed'
    },
  },
}
</script>

<style scoped>
</style>

<style lang="scss">
  #employer {
    .jobview .employer .el-card__body{
     padding: 0;
    }
    .jobview .card-body img{
      display: block;
      margin: 15px auto;
      width: 100px;
      height: auto;
    }
    .jobview .employer{
      padding: 0;
    }
  }
  .view-profile {
      text-align: center;
      font-weight: bold;
      padding: 10px;
      background-color: #E87722;
      color: white;
      -webkit-box-shadow: #979797 0 2px 4px 0;
    box-shadow: #979797 0 2px 4px 0;
    border-radius: 0;
    }
</style>
